<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card v-if="handler" style="margin:-30px -20px;">
          <div class="profile-tab-container">
            <div class="calendar-basic-info">
              <div class="date_event">
                <div class="calendar_head">
                  {{
                    getMonthData(calendarNepMonth)
                      ? getMonthData(calendarNepMonth).eng_month
                      : ""
                  }}, {{ calendarEngYear }} ({{ calendarNepYear }}
                  {{
                    getMonthData(calendarNepMonth)
                      ? getMonthData(calendarNepMonth).nep_month
                      : ""
                  }})
                </div>
                <span class="today_date_event"
                  >Today : {{ date.format("mmmm d, yyyy ddd") }}</span
                >
              </div>
              <div class="date_controller">
                <a
                  href="javascript:void(0)"
                  @click.prevent="previousMonth()"
                  :class="yearEndClassPrevious"
                >
                  <v-icon class="arrow">
                    arrow_back_ios
                  </v-icon>
                </a>
                <a
                  href="javascript:void(0)"
                  @click.prevent="nextMonth()"
                  :class="yearEndClassNext"
                >
                  <v-icon class="arrow">
                    arrow_forward_ios
                  </v-icon>
                </a>
              </div>
            </div>
          </div>
        </v-card>

        <div :class="handler ? 'calendar-container' : ''">
          <v-card-title class="head_title">
            <div class="data-represent">
              <div>
                <span class="d-success"></span> &nbsp;
                <strong>{{ 0 }}</strong>
                <small> Exam</small>
              </div>

              <div>
                <span class="d-warning"></span> &nbsp;
                <strong>{{ totalEventCount }}</strong>
                <small> Event</small>
              </div>

              <div>
                <span class="d-primary"></span> &nbsp; <strong> </strong>
                <small> Today</small>
              </div>

              <div>
                <span class="d-error"></span> &nbsp;
                <strong>
                  {{ schoolHolidayCount }}
                </strong>
                <small> School Holiday</small>
              </div>
            </div>
          </v-card-title>
          <table class="table">
            <thead>
              <tr>
                <th
                  v-for="(header, index) in headers"
                  :key="index"
                  :class="header.name === 'saturday' ? 'saturday' : ''"
                >
                  {{ header.name.capitalizeFirstLetter() }}
                  <p class="header_nepali">({{ header.nep_day }})</p>
                </th>
              </tr>
            </thead>
            <tbody v-if="maxRow > 0">
              <tr v-for="(n, i) in maxRow" :key="i">
                <td
                  v-for="(header, index) in headers"
                  :key="index"
                  :class="constructClass(header.name, rows, i) + 'tooltip_app'"
                  @click="handler ? addSchoolEvent(rows, i, header) : ''"
                >
                  <span
                    v-if="
                      rows[header.name].length &&
                        !['', undefined, 'undefined', null].includes(
                          rows[header.name][i]
                        ) &&
                        rows[header.name]
                    "
                  >
                    <span class="event_title">{{
                      rows[header.name][i].is_holiday
                        ? rows[header.name][i].event_title
                        : ""
                    }}</span>
                    <span class="nepDate">{{
                      rows[header.name][i].gate_nep
                    }}</span>
                    <span
                      class="engMonth"
                      v-if="rows[header.name][i].gate_eng === 1"
                      >{{
                        getEngMonth(rows[header.name][i].mahina_eng_id)
                      }}.</span
                    >
                    <span
                      class="school_event"
                      v-if="
                        schoolEventDates.includes(rows[header.name][i].gate_nep)
                      "
                    >
                      {{
                        fetchEventString(
                          schoolEventDetail(rows[header.name][i].gate_nep)
                        )
                      }}
                      <!--{{schoolEventDetail(rows[header.name][i].gate_nep) ? schoolEventDetail(rows[header.name][i].gate_nep).event_title.substr(0,20)+ '...' :''}}-->
                    </span>
                    <span
                      class="public_holiday"
                      v-if="rows[header.name][i].is_holiday"
                    ></span>
                    <span class="tithi">{{ rows[header.name][i].tithi }}</span>
                    <span class="engDate">{{
                      rows[header.name][i].gate_eng
                    }}</span>
                    <div
                      class="tooltiptext"
                      v-if="
                        schoolEventDates.includes(rows[header.name][i].gate_nep)
                      "
                    >
                      {{
                        schoolEventDetail(rows[header.name][i].gate_nep)
                          ? schoolEventDetail(rows[header.name][i].gate_nep)
                              .event_title
                          : ""
                      }}
                    </div>
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7">
                  <not-found></not-found>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-flex>
    </v-layout>
    <v-dialog v-model="eventDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Add/Update</span>
        </v-card-title>
        <v-card-text class="pb-1 pt-3">
          <v-form
            ref="form"
            @keydown.native="form.errors.clear($event.target.name)"
            @keyup.enter="save"
            lazy-validation
          >
            <v-text-field
              outlined
              dense
              autocomplete="off"
              label="Event Title*"
              required
              class="pa-0"
              v-model="form.event_title"
              name="event_title"
              :error-messages="form.errors.get('event_title')"
            />

            <v-checkbox
              style="margin-top: 0;"
              v-if="button === 'Save'"
              color="blue"
              v-model="form.range"
            >
              <template v-slot:label>
                <div style="color:#333;">
                  Date Range
                  <p style="line-height: 10px">
                    <small style="color:#666;font-size: 10px;">
                      Check if event is for multiple days in a row.
                    </small>
                  </p>
                </div>
              </template>
            </v-checkbox>

            <v-calendar-field
              id-val="from_date"
              v-model="form.event_date"
              :label="dateEnable ? 'Event Date' : 'From Date'"
            >
            </v-calendar-field>

            <!-- <v-text-field outlined dense :label=" dateEnable ? 'Event Date': 'From Date'" v-mask="'####-##-##'"
                                      autocomplete="off"
                                      required class="pa-0" v-model="form.event_date"
                                      name="batch_start"
                                      :error-messages="form.errors.get('event_date')" :disabled="dateEnable"/> -->
            <v-calendar-field
              v-if="!dateEnable && button === 'Save'"
              id-val="to_date"
              v-model="form.to_date"
              label="To Date*"
            >
            </v-calendar-field>

            <!-- <v-text-field outlined dense xs12 v-if="!dateEnable && button === 'Save'" label="To Date*"
                                      v-mask="'####-##-##'" autocomplete="off"
                                      required class="pa-0" v-model="form.to_date"
                                      name="to_date"
                                      :error-messages="form.errors.get('to_date')" :disabled="dateEnable"/> -->

            <v-checkbox color="#ff5252" v-model="form.is_holiday">
              <template v-slot:label>
                <div style="color:#333;">
                  <span style="color:#ff5252">Mark as school holiday</span>
                  <p style="line-height: 10px">
                    <small style="color:#666;font-size: 10px;">
                      Check if the above selected event is a school holiday.
                    </small>
                  </p>
                </div>
              </template>
            </v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="(eventDialog = false), form.reset()"
            >Close</v-btn
          >
          <v-btn
            color="error"
            v-if="button === 'Update'"
            text
            @click="deleteData(form.id)"
            >Delete</v-btn
          >
          <v-btn color="success" text @click="save">{{ button }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import { nepNumberToEng, engNumberToNep } from "@/library/helpers";

const NepaliDate = require("nepali-date");
const nd = new NepaliDate();

export default {
  props: {
    handler: {
      default: true,
      type: Boolean,
    },
  },
  data: () => ({
    form: new Form(
      {
        event_title: "",
        event_date: "",
        is_holiday: "",
        range: 0,
        to_date: "",
      },
      "/api/calendar"
    ),
    button: "Save",
    date: nd,
    dateEnable: true,
    yearEndClassNext: "",
    yearEndClassPrevious: "",
    headers: [
      { name: "sunday", nep_day: "आइत ", day: "1" },
      { name: "monday", nep_day: "सोम", day: "2" },
      { name: "tuesday", nep_day: "मंगल", day: "3" },
      { name: "wednesday", nep_day: "बुध", day: "4" },
      { name: "thursday", nep_day: "बिहि", day: "5" },
      { name: "friday", nep_day: "शुक्र", day: "6" },
      { name: "saturday", nep_day: "शनि", day: "7" },
    ],
    engMonths: [
      { month: 1, name: "Jan" },
      { month: 2, name: "Feb" },
      { month: 3, name: "Mar" },
      { month: 4, name: "Apr" },
      { month: 5, name: "May" },
      { month: 6, name: "Jun" },
      { month: 7, name: "Jul" },
      { month: 8, name: "Aug" },
      { month: 9, name: "Sep" },
      { month: 10, name: "Oct" },
      { month: 11, name: "Nov" },
      { month: 12, name: "Dec" },
    ],
    months: [
      {
        month: "01",
        nep_month: "वैशाख",
        nep_month_eng: "Baishak",
        eng_month: "Apr/May",
      },
      {
        month: "02",
        nep_month: "जेठ",
        nep_month_eng: "Jestha",
        eng_month: "May/Jun",
      },
      {
        month: "03",
        nep_month: "असार",
        nep_month_eng: "Ashad",
        eng_month: "Jun/Jul",
      },
      {
        month: "04",
        nep_month: "साउन",
        nep_month_eng: "Shrwan",
        eng_month: "Jul/Aug",
      },
      {
        month: "05",
        nep_month: "भदौ",
        nep_month_eng: "Bhadra",
        eng_month: "Aug/Sep",
      },
      {
        month: "06",
        nep_month: "असोज",
        nep_month_eng: "Ashoj",
        eng_month: "Sep/Oct",
      },
      {
        month: "07",
        nep_month: "कार्तिक",
        nep_month_eng: "Kartik",
        eng_month: "Oct/Nov",
      },
      {
        month: "08",
        nep_month: "मंसिर",
        nep_month_eng: "Mangsir",
        eng_month: "Nov/Dec",
      },
      {
        month: "09",
        nep_month: "पुष",
        nep_month_eng: "Poush",
        eng_month: "Dec/Jan",
      },
      {
        month: "10",
        nep_month: "माघ",
        nep_month_eng: "Magh",
        eng_month: "Jan/Feb",
      },
      {
        month: "11",
        nep_month: "फागुन",
        nep_month_eng: "Falgun",
        eng_month: "Feb/Mar",
      },
      {
        month: "12",
        nep_month: "चैत",
        nep_month_eng: "Chaitra",
        eng_month: "Mar/Apr",
      },
    ],
    maxRow: 0,
    rows: [],
    data: [],
    calendarNepMonth: "",
    calendarNepYear: "",
    calendarEngYear: "",
    calendarEngMonth: "",
    loading: true,

    eventDialog: false,
    schoolEventDates: [],
    schoolEvents: [],
    schoolHolidayCount: 0,
    totalEventCount: 0,
  }),

  computed: {
    ...mapState(["batch"]),
  },
  watch: {
    "form.range": function(v) {
      this.dateEnable = true;
      this.form.to_date = "";
      if (v) {
        this.dateEnable = false;
        this.form.to_date = this.form.event_date;
      }
    },
  },
  mounted() {
    this.calendarNepMonth = nd.format("MM");
    this.calendarNepYear = nd.format("YYYY");
    this.calendarEngMonth = new Date().getMonth().pad();
    this.calendarEngYear = new Date().getFullYear();
    this.getData();
    this.getSchoolEvents();
  },
  methods: {
    getData() {
      if (this.loading) {
        this.$rest
          .get(
            "api/main-calendar?saal=" +
              this.calendarNepYear +
              "&mahina=" +
              this.calendarNepMonth
          )
          .then((response) => {
            this.data = response.data.data;
            if (this.data.length) {
              this.headers.map((res) => {
                let sundayIndexing = 0;
                let mondayIndexing = 0;
                let tuesdayIndexing = 0;
                let wednesdayIndexing = 0;
                let thursdayIndexing = 0;
                let fridayIndexing = 0;
                let saturdayIndexing = 0;

                if (this.data[0].baar > 1) sundayIndexing = 1;
                if (this.data[0].baar > 2) mondayIndexing = 1;
                if (this.data[0].baar > 3) tuesdayIndexing = 1;
                if (this.data[0].baar > 4) wednesdayIndexing = 1;
                if (this.data[0].baar > 5) thursdayIndexing = 1;
                if (this.data[0].baar > 6) fridayIndexing = 1;
                if (this.data[0].baar > 7) saturdayIndexing = 1;

                this.dayWiseDataManage(
                  res,
                  "sunday",
                  this.rows,
                  1,
                  sundayIndexing
                );
                this.dayWiseDataManage(
                  res,
                  "monday",
                  this.rows,
                  2,
                  mondayIndexing
                );
                this.dayWiseDataManage(
                  res,
                  "tuesday",
                  this.rows,
                  3,
                  tuesdayIndexing
                );
                this.dayWiseDataManage(
                  res,
                  "wednesday",
                  this.rows,
                  4,
                  wednesdayIndexing
                );
                this.dayWiseDataManage(
                  res,
                  "thursday",
                  this.rows,
                  5,
                  thursdayIndexing
                );
                this.dayWiseDataManage(
                  res,
                  "friday",
                  this.rows,
                  6,
                  fridayIndexing
                );
                this.dayWiseDataManage(
                  res,
                  "saturday",
                  this.rows,
                  7,
                  saturdayIndexing
                );
              });
              let r = [
                this.rows["sunday"].length,
                this.rows["monday"].length,
                this.rows["tuesday"].length,
                this.rows["thursday"].length,
                this.rows["friday"].length,
                this.rows["saturday"].length,
              ];

              this.maxRow = r.reduce(function(a, b) {
                return Math.max(a, b);
              });

              // check all r values equal
              if (!r.every((val, i, arr) => val === arr[0])) {
                this.maxRow = this.maxRow - 1;
              }

              this.loading = false;
            } else {
              this.maxRow = 0;
              this.data = [];
              this.rows = [];
            }
          });
      }
    },
    dayWiseDataManage(res, day, rows, baar, indexing) {
      if (res.name === day) {
        rows[res.name] = [];
        this.data.map((d, i) => {
          rows[res.name][indexing] = {};
          if (d.baar === baar) {
            rows[res.name][indexing].id = d.id;
            rows[res.name][indexing].gate_nep = d.gate_nep;
            rows[res.name][indexing].gate_eng = d.gate_eng;
            rows[res.name][indexing].baar = d.baar;
            rows[res.name][indexing].mahina_nep_id = d.mahina_nep_id;
            rows[res.name][indexing].mahina_eng_id = d.mahina_eng_id;
            rows[res.name][indexing].saal_nep = d.saal_nep;
            rows[res.name][indexing].saal_eng = d.saal_eng;
            rows[res.name][indexing].is_holiday = d.is_holiday;
            rows[res.name][indexing].event_title = d.event_title;
            rows[res.name][indexing].tithi = d.tithi;
            rows[res.name][indexing].is_current = d.is_current;
            indexing++;
          }
        });
      }
    },

    fetchEventString(evString) {
      if (!evString) return "";

      let output = evString.event_title.substr(0, 20);
      if (evString.event_title.length > 20) output += "...";
      return output;
    },

    constructClass(day, rows, i) {
      let className = "";

      if (["", undefined, "undefined", null].includes(rows[day][i]))
        return className;

      if (
        !["", undefined, "undefined", null].includes(rows[day][i]) &&
        rows[day][i].gate_nep
      ) {
        className = "td_cursor ";
      }
      if (day === "saturday") {
        className += "saturday ";
      }
      if (this.schoolEventDates.includes(rows[day][i].gate_nep)) {
        let event = this.schoolEventDetail(rows[day][i].gate_nep);
        if (event && event.is_holiday) {
          className += "school_holiday ";
        } else {
          className += "school_event_box ";
        }
      }

      if (rows[day][i].is_holiday) {
        className += "public_holiday ";
      }

      if (rows[day][i]) {
        let currentDay =
          rows[day][i].saal_eng +
          "-" +
          rows[day][i].mahina_eng_id +
          "-" +
          rows[day][i].gate_eng;
        let d = new Date();
        let cDay =
          d.getFullYear() +
          "-" +
          (parseInt(d.getMonth()) + 1) +
          "-" +
          d.getDate();
        if (currentDay === cDay) {
          className += " current_day ";
        }
        // console.log(currentDay, cDay)
      }
      return className;
    },
    getEngMonth(month) {
      // console.log(month);
      let monthName = this.engMonths.find((res) => res.month === month);
      return monthName.name;
    },
    getMonthData(month) {
      // return month;
      return this.months.find((res) => res.month === month);
    },
    nextMonth() {
      this.loading = true;
      // this.yearEndClassNext = '';
      // this.yearEndClassPrevious = '';
      this.calendarNepMonth = (parseInt(this.calendarNepMonth) + 1).pad();

      if (this.calendarNepMonth === "13") {
        this.calendarNepMonth = "01";
        this.calendarNepYear = parseInt(this.calendarNepYear) + 1;
      }
      this.getSchoolEvents();
      this.getData();
    },
    previousMonth() {
      this.loading = true;

      this.calendarNepMonth = (parseInt(this.calendarNepMonth) - 1).pad();
      if (this.calendarNepMonth === "00") {
        this.calendarNepMonth = "12";
        this.calendarNepYear = parseInt(this.calendarNepYear) - 1;
      }

      this.getSchoolEvents();
      this.getData();
    },
    getSchoolEvents() {
      this.$rest
        .get(
          "api/calendar?saal=" +
            this.calendarNepYear +
            "&mahina=" +
            this.calendarNepMonth
        )
        .then((response) => {
          this.schoolEvents = response.data.data;
          this.schoolEventDates = [];
          this.totalEventCount = response.data.data.length;
          this.schoolHolidayCount = 0;
          let $this = this;
          response.data.data.map((res) => {
            if (res.is_holiday === 1) {
              $this.schoolHolidayCount += 1;
            }

            if (res.event_date)
              var nepaliNumber = engNumberToNep(res.event_date.split("-")[2]);
            if (nepaliNumber.charAt(0) === "०")
              nepaliNumber = nepaliNumber.slice(1);
            this.schoolEventDates.push(nepaliNumber);
          });
        });
    },
    addSchoolEvent(data, i, header) {
      if (!this.$auth.can("calendar-add-event")) {
        // this.$events.fire('notification', {message: 'You are not allowed to create event', status: 'error'});
        return;
      }

      data = data[header.name] ? data[header.name][i] : undefined;
      this.form.event_title = "";
      this.form.is_holiday = "";
      this.button = "Save";

      if (data) {
        let date =
          data.saal_nep +
          "-" +
          data.mahina_nep_id.pad() +
          "-" +
          parseInt(nepNumberToEng(data.gate_nep)).pad();
        let today = nd.format("YYYY-MM-DD");
        if (date > today) this.eventDialog = true;
        else
          this.$events.fire("notification", {
            message: "Events can only be set on following dates",
            status: "error",
          });

        if (this.schoolEventDates.includes(data.gate_nep)) {
          let event = this.schoolEventDetail(data.gate_nep);
          this.form.event_title = event.event_title;
          this.form.id = event.id;
          this.form.is_holiday = event.is_holiday;
          this.button = "Update";
        } else {
          this.button = "Save";
        }
        this.form.event_date = date;
      }
    },
    save() {
      this.form.fireFetch = false;
      if (this.form.is_holiday) {
        this.form.is_holiday = 1;
      } else {
        this.form.is_holiday = 0;
      }
      // this.form.range = 0;
      if (this.form.range) {
        this.form.range = 1;
      } else {
        this.form.range = 0;
      }
      this.form.store().then((data) => {
        this.eventDialog = false;
        this.getData();
        this.getSchoolEvents();
      });
    },
    schoolEventDetail(gate_nep) {
      let date =
        this.calendarNepYear +
        "-" +
        this.calendarNepMonth +
        "-" +
        parseInt(nepNumberToEng(gate_nep)).pad();
      return this.schoolEvents.find((res) => res.event_date === date);
      // return gate_nep;
    },
    deleteData(id) {
      this.form.fireFetch = false;
      this.form.delete(id).then((res) => {
        this.eventDialog = false;
        this.getData();
        this.getSchoolEvents();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: none;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.table {
  border-collapse: collapse;
  width: 100%;
}

.today_date_event {
  font-weight: bold;
  color: #333;
}

.date_controller {
  margin-top: 15px;

  a {
    text-align: center;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    display: inline-block;
    font-size: 10px !important;
    background-color: white;
    padding: 4px 15px;
    border-radius: 3px;
    font-weight: bold;
    color: #1976d2 !important;

    span {
      font-weight: bold;
      color: #1976d2 !important;
    }

    text-decoration: none !important;
    margin-left: 10px;

    i {
      font-size: 14px;
    }
  }
}

.calendar-basic-info {
  width: 90%;
  margin: 20px auto 0 auto;
  padding: 15px;
  display: flex;
  justify-content: space-between;

  .calendar_head {
    font-size: 21px;
    font-weight: bold;
    color: white;
  }
}

.saturday:not(th):not(.current_day):not(.school_holiday) {
  color: #ff5252 !important;

  &:hover {
    color: #ff5252 !important;
  }
}

.current_day {
  background-color: #b5d9fd !important;
  color: #444 !important;
}

.school_holiday {
  color: white !important;
}

.header_nepali {
  font-size: 14px;
  color: #888;
  margin-bottom: 5px !important;
}

.td_cursor {
  cursor: pointer !important;
}

.td_cursor:not(.school_holiday):not(.current_day):not(.school_event_box):not(.public_holiday) {
  &:hover {
    background-color: #ebf5ff !important;
    color: #444 !important;
  }

  &.saturday:hover {
    background-color: #ebf5ff !important;
    color: #ff5252 !important;
  }
}

.public_holiday:hover:not(.school_holiday) {
  background-color: #ebf5ff !important;
  color: #ff5252 !important;
}

.calendar-container {
  position: relative;
  z-index: 1;
  width: 90%;
  margin: -80px auto 0 auto;
}

.table,
tr,
th,
td {
  font-size: 18px;
  text-align: center;
  border: 1px solid black;
}

.table,
td {
  padding: 25px;
}

.table th {
  line-height: 1.5 !important;
  padding: 10px 0;
}

.head_title {
  background: white;
  color: #333;
}

.table {
  background: white;

  td,
  th {
    color: #444;
    border-color: #e9e9e9;
  }
}

.engDate {
  font-size: 10px;
  position: absolute;
  margin-top: 55px;
  margin-left: 28px;
}

.engMonth {
  font-size: 10px;
  position: absolute;
  margin-top: 55px;
  margin-left: 5px;
}

.tithi {
  position: absolute;
  font-size: 10px;
  margin-left: -65px;
  margin-top: 55px;
}

.event_title {
  position: absolute;
  font-size: 10px;
  margin-left: -45px;
  margin-top: -24px;
  width: 122px;
}

.saturday {
  /*background: red;*/
  color: #b71c1c;
}

.school_holiday {
  background: #ff5252;
  color: white;
}

.current_day {
  background: green;
  color: white;
}

.nepDate {
  font-size: 35px;
}

.calendar_head {
  font-size: 25px;
}

span.public_holiday {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #ff5252 !important;
  border-radius: 52%;
  display: inline-block;
  margin-left: -70px;
  margin-top: 40px;
}

td.public_holiday {
  color: #ff5252;
  /*box-shadow: inset 5px 0px 0px 0px #ff5252;*/
  /*box-sizing: border-box;*/
  /*border-left: 5px inset #ff5252;*/
}

.public_holiday_notification {
  height: 15px;
  width: 15px;
  background-color: #b71c1c;
  border-radius: 52%;
  display: inline-block;
  margin-right: 10px;
}

.calendar_info {
  margin-top: 25px;
}

.school_event {
  position: absolute;
  margin-top: 42px;
  margin-left: -76px;
  font-size: 10px;
  width: 122px;
}

.school_event_box {
  color: #444 !important;
  background-color: #f9d6a9 !important;
}

/*.tooltip {*/
/*position: relative;*/
/*display: inline-block;*/
/*border-bottom: 1px dotted black; !* If you want dots under the hoverable text *!*/
/*}*/

/* Tooltip text */
.tooltiptext {
  transition: 0.5s;
  opacity: 0;
  max-width: 200px;
  line-height: 1;
  font-size: 13px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 3px;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip_app:hover .tooltiptext {
  opacity: 1;
}

.yearEndClassNext,
.yearEndClassPrevious {
  background: #ccc !important;
  cursor: not-allowed;

  i {
    color: white !important;
  }
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 30px;
}
</style>
